<div class="vz-app-root layout vertical flex" [class.vz-mobile]="mobile()">

    @if (newVersionReady && !newVersionHidden) {
        <div class="vz-new-version-panel layout center-center" [@removeH]>
            <div class="vz-nvp-icon layout vertical">
                <i class="vzi-info"></i>
            </div>
            <div class="vz-nvp-text layout center gap05 wrap">
                <div class="layout flex" [class.fs-s]="mobile()">Доступна новая версия приложения. Перезагрузите страницу для продолжения работы.</div>
                <button nz-button nzType="primary" (click)="reload()">Перезагрузить</button>
                <button nz-button nzType="dashed" (click)="newVersionHidden = true">Позже</button>
            </div>
        </div>
    }

    @if (suspended) {
        <div class="vz-new-version-panel suspended layout center-center" style="position: absolute; top: 0; left: 0; right: 0" [@removeH]>
            <div class="vz-nvp-icon layout vertical">
                <i class="vzi-warning"></i>
            </div>
            <div class="vz-nvp-text layout center">
                <div class="layout flex mr1" [class.fs-s]="mobile()">Организация заблокирована до уплаты задолженности. Доступен только просмотр данных.</div>
                <i class="vzi-cross vz-btn" (click)="suspended = false"></i>
            </div>
        </div>
    }

    <div class="layout flex relative">
        @if (crash) {
            <div class="vz-app-crash">
                <div class="layout relative bg nt-error" [ngClass]="mobile() ? 'p05 m025' : 'p1'">
                    <i class="vzi-shocked txt-err fs-5xl"></i>
                    <div class="layout vertical" [ngClass]="mobile() ? 'ml05' : 'ml1'">
                        <span class="txt-err mv025">В приложении произошла ошибка</span>
                        <span>Пожалуйста {{ __WEB_REAL ? 'перезагрузите страницу' : 'перезапустите приложение' }} во избежании некорректной работы.</span>
                        <div class="layout end-justified" [ngClass]="mobile() ? 'vertical end gap025 mv05' : 'center gap05'">
                            @if (reportSent) {
                                <span class="txt-err">
                                    Обращение <b class="bg-err br6 p025 ph05 txt-inv">{{ reportSent }}</b> создано.<br>
                                    Дальнейшую информацию о нем вы можете найти в разделе "Тех. поддержка".
                                </span>
                            }
                            @else {
                                <button nz-button nzType="primary" (click)="createTicket()">Переслать данные об ошибке {{ mobile() ? '' : 'в тех.поддержку' }}</button>
                            }
                            <button nz-button (click)="crash = undefined; reportSent = undefined;">Закрыть</button>
                        </div>
                    </div>
                    @if (reportSending) {
                        <div class="fit bg-dd op08 layout center-center">
                            <div class="loader small"></div>
                        </div>
                    }
                </div>
            </div>
        }
        <router-outlet />
    </div>

</div>

<router-outlet name="g" />
